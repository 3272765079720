<template>
 <!-- <div
    :class="[
      active && isSuccess && closeTimer
        ? 'relative bg-green-300'
        : active && !isSuccess && closeTimer
        ? 'relative bg-red-300'
        : '',
    ]"
  >
    <div class="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8 banner_min_height">
      <div class="pr-16 sm:text-center sm:px-16">
        <p class="font-medium text-white">
          <span v-show="active && closeTimer" class="hidden md:inline text-sm">
            {{ msg }}
          </span>
        </p>
      </div>
      <div
        class="absolute inset-y-0 right-0 pr-1 flex items-start sm:pt-0.5 pt-0.5 sm:pr-2 sm:items-start"
      >
        <button
          @click="$emit('closeBanner')"
          type="button"
          :class="[
            isSuccess ? 'hover:bg-green-500' : 'hover:bg-red-500',
            'flex p-1 rounded-md focus:outline-none focus:ring-2 focus:ring-white',
          ]"
        >
          <span class="sr-only">Dismiss</span>
          <XIcon class="h-3 w-3 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>

 -->

 <div
  aria-live="assertive"
  class="z-50 fixed inset-x-0 inset-y-10 flex items-start px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
 >
  <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
   <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->

   <div
    v-show="active && closeTimer"
    :class="[
     !isSuccess ? 'bg-red-500' : 'bg-green-500',
     'max-w-md w-full shadow-xl rounded-lg pointer-events-auto ring-2 ring-black ring-opacity-5 overflow-hidden',
    ]"
   >
    <div class="p-4">
     <div class="flex items-start">
      <div v-if="active && isSuccess && closeTimer" class="flex-shrink-0">
       <CheckCircleIcon class="h-6 w-6 text-green-50" aria-hidden="true" />
      </div>
      <div v-else-if="active && !isSuccess && closeTimer" class="flex-shrink-0">
       <XCircleIcon class="h-6 w-6 text-red-50" aria-hidden="true" />
      </div>
      <div class="ml-3 w-0 flex-1 pt-0.5">
       <p v-show="!isSuccess" class="text-sm font-medium text-red-50">
        {{ $t("somethingWentWrong") }} !
       </p>
       <p :class="[!isSuccess ? 'text-red-50 mt-1' : 'font-medium text-green-50', 'text-sm']">
        {{ msg }}
       </p>
      </div>
      <div class="ml-4 flex-shrink-0 flex">
       <button
        @click="$emit('closeBanner')"
        class="rounded-md inline-flex text-gray-50 hover:text-gray-100 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-gray-50"
       >
        <span class="sr-only">Close</span>
        <XIcon class="h-5 w-5" aria-hidden="true" />
       </button>
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/outline";
import { XIcon } from "@heroicons/vue/solid";

export default {
 props: ["msg", "active", "isSuccess"],
 data() {
  return {
   closeTimer: false,
  };
 },
 components: {
  XIcon,
  XCircleIcon,
  CheckCircleIcon,
 },
 methods: {
  openBanner() {
   this.closeTimer = true;
   let v = this;
   setTimeout(function () {
    v.closeTimer = false;
    v.$emit("bannerTimer");
    v.$forceUpdate();
   }, 3000);
  },
 },
 mounted() {
  this.openBanner();
 },
};
</script>

<style>
.banner_min_height {
 height: 25px;
}
</style>
